import { sha256 } from 'js-sha256';
import { useContext, useEffect } from 'react';
import {
  AuthContext,
  type IAuthContext,
  type TAuthConfig,
  type TRefreshTokenExpiredEvent,
} from 'react-oauth2-code-pkce';
import { Navigate } from 'react-router-dom';

const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, '0');
};

const generateCodeVerifier = (len: number) => {
  const arr = new Uint8Array(len / 2);

  return Array.from(window.crypto.getRandomValues(arr), dec2hex).join('');
};

const code_verifier = sessionStorage.getItem('code_verifier') ?? generateCodeVerifier(128);
sessionStorage.setItem('code_verifier', code_verifier);

const toBase64UrlSafe = (code: string) =>
  btoa(String.fromCharCode(...new Uint8Array(sha256.digest(code))))
    .replaceAll('+', '-')
    .replaceAll('/', '_')
    .replaceAll('=', '');

export const cognitoAuthConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID ?? '',
  authorizationEndpoint: `https://${process.env.REACT_APP_AUTH_ENDPOINT}/oauth2/authorize`,
  extraAuthParameters: {
    code_challenge: toBase64UrlSafe(sessionStorage.getItem('code_verifier') ?? code_verifier),
  },
  tokenEndpoint: `https://${process.env.REACT_APP_AUTH_ENDPOINT}/oauth2/token`,
  extraTokenParameters: {
    code_verifier,
  },
  redirectUri: `${window.location.origin}/auth/callback`,
  logoutEndpoint: `https://${process.env.REACT_APP_AUTH_ENDPOINT}/logout`,
  extraLogoutParameters: { logout_uri: window.location.origin },
  scope: 'openid', // Gives idToken for cognito authorizer
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
    event.logIn(undefined, undefined, 'popup'),
  autoLogin: false,
};

export const Login = () => {
  const { logIn } = useContext<IAuthContext>(AuthContext);
  useEffect(() => {
    logIn();
  }, [logIn]);
  return <></>;
};

export const Callback = (): JSX.Element => {
  const { idToken, loginInProgress } = useContext<IAuthContext>(AuthContext);

  if (idToken) {
    return <Navigate to={'/Bookings'} />;
  }

  if (loginInProgress) {
    return <></>;
  }

  return <Navigate to={'/login'} />;
};

export const Logout = () => {
  const { logOut } = useContext<IAuthContext>(AuthContext);
  useEffect(() => {
    logOut();
  }, [logOut]);
  return <></>;
};
