import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import 'reactjs-popup/dist/index.css';

import {
  BO_CONSECUTIVE,
  BO_PARALLEL,
  Course,
  Game,
  type Players,
  type WalkInBookingRequest,
  timezonelessDateFormat,
} from 'common';

import { Api } from '../clientAPI/api';
import dayjs from '../dayjsWrapper';

export interface CreateWalkInFormFields {
  contact: {
    name: string;
    phone: string;
    email: string;
    organisation: string;
  };
  players: Players;
  game: Game;
  course: Course;
  order: typeof BO_CONSECUTIVE | typeof BO_PARALLEL;
  message: string;
}

interface CreateWalkInFormProps {
  open: boolean;
  onClose: () => void;
}

export const CreateWalkInForm = ({ open, onClose }: CreateWalkInFormProps) => {
  const methods = useForm<CreateWalkInFormFields>({
    defaultValues: {
      contact: { name: '', organisation: '', phone: '', email: '' },
      game: Game.SG9,
      course: Course.Front,
      order: BO_CONSECUTIVE,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isSubmitting, isDirty },
    reset,
    control,
  } = methods;

  const resetAndClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<CreateWalkInFormFields> = async (data) => {
    try {
      const token = localStorage.getItem('ROCP_idToken');
      const result = await fetch(`${Api.supaGolfApiUrl(window.location.origin)}/admin/bookings`, {
        method: 'POST',
        headers: {
          ...(token && { Authorization: `Bearer ${JSON.parse(token)}` }),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mapFormFieldsToWalkInBookingRequest(data)),
      });

      if (result.status >= 500) {
        setError('root.serverError', { message: 'Something went wrong' });
        return;
      } else if (result.status >= 400) {
        const response: { message: string } = await result.json();

        const lowerMessage = response.message.toLocaleLowerCase();
        if (lowerMessage.includes('name')) {
          setError('contact.name', { message: response.message });
        } else if (lowerMessage.includes('phone')) {
          setError('contact.phone', { message: response.message });
        } else if (lowerMessage.includes('player') || lowerMessage.includes('kids')) {
          setError('root.totalPlayers', { message: response.message });
        } else if (lowerMessage.includes('message')) {
          setError('message', { message: response.message });
        } else {
          setError('root.serverError', { message: response.message });
        }
        return;
      }

      resetAndClose();
      return;
    } catch (e) {
      setError('root.serverError', { message: 'An unexpected error occurred, please try again' });
      return;
    }
  };

  const showSlotArrangement = watch('game') !== Game.SP;

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isDirty || window.confirm('You have unsaved changes. Would you like to leave?')) {
          resetAndClose();
        }
      }}
      maxWidth="lg"
      fullWidth={true}
      PaperProps={{ component: 'form', onSubmit: handleSubmit(onSubmit) }}
    >
      <DialogTitle>Book Walk In @ {dayjs().tz().format('DD/MM/YYYY HH:mm')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} md={6} container alignItems="center">
            <Grid item xs>
              <Controller
                control={control}
                name="game"
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    select
                    label="Supa Golf or Supa Putt"
                    required
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={!!error}
                  >
                    <MenuItem value={Game.SG9}>{Game.SG9}</MenuItem>
                    <MenuItem value={Game.SG18}>{Game.SG18}</MenuItem>
                    <MenuItem value={Game.SP}>{Game.SP}</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item>
              {watch('game') === Game.SG18 ? (
                <img src={'/images/18.png'} alt="18 Hole" width={56} height={56} />
              ) : (
                <img src={'/images/9.png'} alt="9 Hole" width={56} height={56} />
              )}
            </Grid>
          </Grid>
          {showSlotArrangement ? (
            <>
              <Grid item xs={6} md={3}>
                <Controller
                  control={control}
                  name="course"
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <TextField
                      select
                      label="Course"
                      required
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={!!error}
                    >
                      <MenuItem value={Course.Front}>{Course.Front}</MenuItem>
                      <MenuItem value={Course.Back}>{Course.Back}</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Controller
                  control={control}
                  name="order"
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <TextField
                      select
                      label="Group Order"
                      required
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={!!error}
                    >
                      <MenuItem value={BO_CONSECUTIVE}>{BO_CONSECUTIVE}</MenuItem>
                      <MenuItem value={BO_PARALLEL}>{BO_PARALLEL}</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={0} md={6} />
          )}
          <Grid item xs={6} md={3}>
            <TextField
              type="number"
              label="Adults"
              fullWidth
              inputProps={{ min: 0 }}
              {...register('players.adults', {
                min: { value: 0, message: 'Enter a value above 0' },
                setValueAs: setValueAsPositiveNumber,
              })}
              error={!!errors.players?.adults || !!errors.root?.totalPlayers}
              helperText={errors.players?.adults?.message}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              type="number"
              label="Kids (5 - 14yrs)"
              fullWidth
              inputProps={{ min: 0 }}
              {...register('players.children', {
                min: { value: 0, message: 'Enter a value above 0' },
                setValueAs: setValueAsPositiveNumber,
              })}
              error={!!errors.players?.children || !!errors.root?.totalPlayers}
              helperText={errors.players?.children?.message}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              type="number"
              label="Kids (0 - 4yrs)"
              fullWidth
              inputProps={{ min: 0 }}
              {...register('players.infants', {
                min: { value: 0, message: 'Enter a value above 0' },
                setValueAs: setValueAsPositiveNumber,
              })}
              error={!!errors.players?.infants || !!errors.root?.totalPlayers}
              helperText={errors.players?.infants?.message}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              type="number"
              label="Concessions"
              fullWidth
              inputProps={{ min: 0 }}
              {...register('players.seniors', {
                min: { value: 0, message: 'Enter a value above 0' },
                setValueAs: setValueAsPositiveNumber,
              })}
              error={!!errors.players?.seniors || !!errors.root?.totalPlayers}
              helperText={errors.players?.seniors?.message}
            />
          </Grid>
          {errors.root?.totalPlayers && (
            <Grid item xs={12}>
              <FormControl>
                <FormHelperText error>{errors.root.totalPlayers.message}</FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Full Name"
              placeholder="Enter the name"
              fullWidth
              {...register('contact.name')}
              error={!!errors.contact?.name}
              helperText={errors?.contact?.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="tel"
              label="Mobile Number"
              placeholder="Enter the phone number"
              fullWidth
              {...register('contact.phone')}
              error={!!errors.contact?.phone}
              helperText={errors.contact?.phone?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="email"
              label="Email"
              placeholder="Enter the email address"
              fullWidth
              {...register('contact.email')}
              error={!!errors.contact?.email}
              helperText={errors.contact?.email?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Company / Org"
              fullWidth
              placeholder="Enter the company name"
              {...register('contact.organisation')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label="Message / Comments"
              placeholder="Enter your message"
              fullWidth
              rows={4}
              {...register('message')}
              error={!!errors.message}
              helperText={errors.message?.message}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="large" color="error" onClick={resetAndClose}>
          Discard
        </Button>
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          size="large"
          color="success"
        >
          <span>Create</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const mapFormFieldsToWalkInBookingRequest = ({
  contact,
  players,
  game,
  course,
  order,
  message,
}: CreateWalkInFormFields): WalkInBookingRequest => ({
  contact: {
    name: contact.name || undefined,
    organisation: contact.organisation || undefined,
    phone: contact.phone || undefined,
    email: contact.email || undefined,
  },
  date: dayjs().tz().format(timezonelessDateFormat),
  game,
  message: message || undefined,
  players,
  slots: game !== Game.SP ? getSlotArrangement(order, course) : undefined,
});

const getSlotArrangement = (
  order: typeof BO_CONSECUTIVE | typeof BO_PARALLEL,
  firstCourse: Course,
): {
  course: Course;
}[] => {
  if (order === BO_PARALLEL) {
    return firstCourse === Course.Front
      ? [{ course: Course.Front }, { course: Course.Back }]
      : [{ course: Course.Back }, { course: Course.Front }];
  }

  return [{ course: firstCourse }];
};

const setValueAsPositiveNumber = (p: string) => {
  const n = parseInt(p);
  return isNaN(n) ? 0 : n;
};
